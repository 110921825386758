import { Calendar } from 'primereact/calendar';

import "./InputCalendar.scss"

function fechaStringAndHour(fecha) {
    const fechaObj = new Date(fecha);
    const opciones = { day: "2-digit", month: "2-digit", year: "numeric" };
    const fechaFormateada = fechaObj.toLocaleDateString("es-ES", opciones);

    // Obtener la hora actual en el formato deseado
    let horas = fechaObj.getHours();
    let minutos = fechaObj.getMinutes();
    let ampm = horas >= 12 ? 'PM' : 'AM';
    horas = horas % 12;
    horas = horas ? horas : 12; // La hora '0' debe ser '12'
    minutos = minutos < 10 ? '0' + minutos : minutos;
    let horaActual = horas + ':' + minutos + ampm;

    // Concatenar la hora al resultado formateado
    const resultadoConHora = `${fechaFormateada}-${horaActual}`;
    
    return resultadoConHora;
}

const InputCalendar = ({value, onChange}) => {
    return(<div className='inputCalendar'>
        <Calendar showTime value={value} onChange={onChange} dateFormat="dd/mm/yy" />
    </div>)
}

export default InputCalendar