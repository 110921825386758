import { useEffect, useState } from "react";

// Components
import AppPageTemplate from "../../Components/AppPageTemplate/AppPageTemplate";
import Input from "../../Components/Input/Input";
import InputCalendar from "../../Components/InputCalendar/InputCalendar";
import Textarea from "../../Components/Textarea/Textarea";
import Dropdown from "../../Components/Dropdown/Dropdown";

// pop up library
import Swal from "sweetalert2";

// db connection
import SteinStore from "stein-js-client";

//Styles
import "./Main.scss";

//Random ID
import { v4 as uuid } from "uuid";

export default function ({ title, cua, asesorData }) {
  //importamos Stein para la base de datos
  const RegistrosAPI = new SteinStore(process.env.REACT_APP_REGISTROS_API);

  //campos de la nueva cita
  const [fechaPrimeraCita, setFechaPrimeraCita] = useState("")
  const [place, setPlace] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientPhone, setClientPhone] = useState("");

  const [clientAge, setClientAge] = useState("");
  const [clientGender, setClientGender] = useState("");
  const [clientChildren, setClientChildren] = useState("");

  const [product, setProduct] = useState(null);

  const [comments, setComments] = useState("");

  //condicionales para funcionamiento de enviar formulario
  const [enviarformulario, setEnviarFormulario] = useState(false); // 1
  const [enviandoformulario, setEnviandoFormulario] = useState(false); // 2

  //Dropdown options
  const AgeRangeOptions = [
    { key: 0, name: "Menor de 25" },
    { key: 1, name: "25 a 30" },
    { key: 2, name: "30 a 40" },
    { key: 3, name: "40 a 50" },
    { key: 4, name: "50 a 60" },
    { key: 4, name: "Mayor de 60" },
  ];
  const GenderOptions = [
    { key: 1, name: "Masculino" },
    { key: 2, name: "Femenino" },
  ];
  const ChildrenOptions = [
    { key: 1, name: "Si" },
    { key: 2, name: "No" },
    { key: 3, name: "No se" },
  ];
  const ProductOptions = [
    { key: 1, name: "Privilegio" }, // VIDA
    { key: 2, name: "Platino" }, // VIDA
    { key: 3, name: "Dotal" }, // VIDA
    { key: 4, name: "Trasciende" }, // VIDA
    { key: 5, name: "Vision Plus" }, // VIDA
    { key: 6, name: "Profesional" }, // VIDA
    { key: 7, name: "Elige" }, // VIDA
    { key: 8, name: "Consolida PPR" }, // VIDA
    { key: 9, name: "Consolida Total" }, // VIDA
    { key: 10, name: "Proyecta" }, // VIDA
    { key: 11, name: "Proyecta Afectó" }, // VIDA
    { key: 12, name: "Capitaliza" },
    { key: 13, name: "Auto" },
    { key: 14, name: "GMM" },
    { key: 15, name: "Hogar Versátil" },
    { key: 16, name: "Mascota" },
  ];





  // funciones
  function fechaStringAndHour(fecha) {
    const fechaObj = new Date(fecha);
    const opciones = { day: "2-digit", month: "2-digit", year: "numeric" };
    const fechaFormateada = fechaObj.toLocaleDateString("es-ES", opciones);

    // Obtener la hora actual en el formato deseado
    let horas = fechaObj.getHours();
    let minutos = fechaObj.getMinutes();
    let ampm = horas >= 12 ? 'PM' : 'AM';
    horas = horas % 12;
    horas = horas ? horas : 12; // La hora '0' debe ser '12'
    minutos = minutos < 10 ? '0' + minutos : minutos;
    let horaActual = horas + ':' + minutos + ampm;

    // Concatenar la hora al resultado formateado
    const resultadoConHora = `${fechaFormateada}-${horaActual}`;
    
    return resultadoConHora;
  }

  // función de enviar formulario a base de datos
  const handleEnviarFormulario = (e) => {
    setEnviandoFormulario(true);
  };

  const resetForm = () => {
    //campos de la nueva cita
    setFechaPrimeraCita("")
    setPlace("");
    setClientName("");
    setClientPhone("");
    setClientAge("");
    setClientGender("");
    setClientChildren("");
    setProduct(null);
    setComments("");

    //condicionales para funcionamiento de enviar formulario
    setEnviarFormulario(false); // 1
    setEnviandoFormulario(false); // 2
  }








  //useEffects
  useEffect(() => {
    if (
      place &&
      clientName &&
      clientPhone &&
      clientAge &&
      clientGender &&
      clientChildren &&
      product
    ) {
      setEnviarFormulario(true);
    } else {
      setEnviarFormulario(false);
    }
  }, [
    fechaPrimeraCita,
    
    place,
    clientName,
    clientPhone,
    clientAge,
    clientGender,
    clientChildren,
    product,

    comments,
  ]);

  useEffect(() => {
    if (enviandoformulario) {
      const loadingSwal = Swal.fire({
        title: "Enviando registro...",
        text: "Por favor espere un momento",
        icon: "info",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        showCloseButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      
      const RegistroDeCita = {
        uuid: `${uuid()}`, // DATA DEL ASESOR
        cua: asesorData?.asesorCua,
        uuid_asesor: asesorData?.uuid,
        nombre_asesor: asesorData?.asesorName,
        telefono_asesor: asesorData?.asesorPhone,
        correo_asesor: asesorData?.asesorEmail,

        estatus: "Prospecto", // DATA POR DEFECTO
        fecha_primera_cita: fechaStringAndHour(fechaPrimeraCita), // aqui le pondremos tambien la hora

        lugar: place,
        nombre_cliente: clientName,
        telefono_cliente: clientPhone,
        edad_cliente: clientAge ? clientAge.name : "",
        genero_cliente: clientGender ? clientGender.name : "",
        con_hijos: clientChildren ? clientChildren.name : "",
        producto: product ? product.name : "",
        
        comentarios: comments,
      };

      RegistrosAPI.append("Registros", [RegistroDeCita], {
        authentication: {
          username: process.env.REACT_APP_REGISTROS_USERNAME,
          password: process.env.REACT_APP_REGISTROS_PASSWORD,
        },
      })
        .then((res) => {
          loadingSwal.close();
          Swal.fire({
            title: "Registro enviado con éxito",
            icon: "success",
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonText: "Enviar otro",
            iconColor: "#0F70D4",
          }).then(() => {
            resetForm()
          });
        })
        .catch((error) => {
          console.error(error);
          setEnviandoFormulario(false);
        });
    }
  }, [enviandoformulario]);








  return (
    <>
      <AppPageTemplate title={title}>
        <h2 className="h2">Nombre *</h2>
        <div style={{ height: "10px" }} />
        <Input
          type={"text"}
          value={clientName}
          onChange={(e) => setClientName(e.target.value)}
        />
        <div style={{ height: "30px" }} />




        <h2 className="h2">Primera cita *</h2>
        <div style={{ height: "10px" }} />
        <InputCalendar
          value={fechaPrimeraCita}
          onChange={(e) => setFechaPrimeraCita(e.value)}
        />
        <div style={{ height: "30px" }} />



        <h2 className="h2">Lugar *</h2>
        <div style={{ height: "10px" }} />
        <Input
          type={"text"}
          value={place}
          onChange={(e) => setPlace(e.target.value)}
        />
        <div style={{ height: "30px" }} />
        


        <h2 className="h2">Teléfono *</h2>
        <div style={{ height: "10px" }} />
        <Input
          type={"number"}
          pattern="[+]?[0-9]*"
          value={clientPhone}
          onChange={(e) =>
            setClientPhone((v) =>
              e.target.validity.valid ? e.target.value : v
            )
          }
        />
        <div style={{ height: "30px" }} />




        <h2 className="h2">Edad *</h2>
        <div style={{ height: "10px" }} />
        <Dropdown
          options={AgeRangeOptions}
          optionLabel={"name"}
          value={clientAge}
          onChange={(e) => setClientAge(e.value)}
        />
        <div style={{ height: "30px" }} />




        <h2 className="h2">Género *</h2>
        <div style={{ height: "10px" }} />
        <Dropdown
          options={GenderOptions}
          optionLabel={"name"}
          value={clientGender}
          onChange={(e) => setClientGender(e.value)}
        />
        <div style={{ height: "30px" }} />



        <h2 className="h2">¿Con hijos menores de edad? *</h2>
        <div style={{ height: "10px" }} />
        <Dropdown
          options={ChildrenOptions}
          optionLabel={"name"}
          value={clientChildren}
          onChange={(e) => setClientChildren(e.value)}
        />
        <div style={{ height: "30px" }} />



        <h2 className="h2">Producto *</h2>
        <div style={{ height: "10px" }} />
        <Dropdown
          options={ProductOptions}
          optionLabel={"name"}
          value={product}
          onChange={(e) => setProduct(e.value)}
        />
        <div style={{ height: "30px" }} />


        <h3 className="h3">Comentarios</h3>
        <div style={{ height: "10px" }} />
        <Textarea
          value={comments}
          onChange={(e) => setComments(e.target.value)}
        />
      </AppPageTemplate>

      {enviarformulario ? (
        <button className="PiButton" onClick={(e) => handleEnviarFormulario(e)}>
          <i className="pi pi-send"></i>
        </button>
      ) : (
        <button className="PiButton PiButtonDisabled">
          <i className="pi pi-send"></i>
        </button>
      )}
    </>
  );
}
